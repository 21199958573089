
.navbrandora{

    align-items: center;
    display: flex;
    /* position: fixed;
    width: 100%; */
  }


  
  #logoImg img {
    width: 120px;
    padding-block: 30px;
    position : fixed;
    padding-left: 68px;
  }
  


  
  
  .button_container {
    position: fixed;
    top: 5%;
    right: 5%;
    height: 0px;
    width: 30px;
    cursor: pointer;
    z-index: 100;
    transition: opacity 0.25s ease;
  
    
  }
  
  .button_container:hover {
    opacity: 0.7;
  }
  
  .button_container.active .top {
    transform: translateY(4px) translateX(0) rotate(47deg);
    background: #FFF;
  }
  /* 
  .button_container.active .middle {
    opacity: 0;
    background: #FFF;
  } */
  
  .button_container.active .bottom {
    transform: translateY(-2px) translateX(0) rotate(-47deg);
    background: #FFF;
  }
  
  .button_container span {
    background: #232323;
    border: none;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 5px;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;
  }
  
  .button_container span:nth-of-type(2) {
    top: 11px;
  }
  
  .button_container span:nth-of-type(3) {
    top: 22px;
  }
  
  .overlay {
    position: fixed;
    background: var(--Secondary);
    color: black;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 0%;
    opacity: 1;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    overflow: hidden;
  
  }
  
  .overlay.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    
  }
  
  .overlay.open li {
    -webkit-animation: fadeInRight 0.5s ease forwards;
    animation: fadeInRight 0.5s ease forwards;
    font-size: 60px;
    font-family: Nexa-bold;
    animation-delay: 0.5s;

  
  }
  
  .overlay.open li:nth-of-type(1) {
  
    animation-delay: 0.5s;
    
  }
  
  
  .overlay.open li:nth-of-type(2) {
  
    animation-delay: 0.8s;
  }
  
  .overlay.open li:nth-of-type(3) {
  
    animation-delay: 1.3s;
  }
  
  .overlay.open li:nth-of-type(4) {
  
    animation-delay: 1.6s;
  }
  
  .overlay nav {
    position: relative;
    height: 70%;
    top: 56%;
    transform: translateY(-50%);
    font-size: 30px;
    font-family: "Vollkorn", serif;
    font-weight: 400;
    text-align: center;
  }
  
  .overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 80%;
  }
  
  .overlay ul li {
    display: block;
    height: 25%;
    height: calc(100% / 4);
    min-height: 50px;
    position: relative;
    opacity: 0;
    
  }
  
  .overlay ul li a {
    display: block;
    position: relative;
    color: #FFF;
    text-decoration: none;
    overflow: hidden;
    font-size: 75px;

    
  }
  
  .overlay ul li a:hover:after,
  .overlay ul li a:focus:after,
  .overlay ul li a:active:after {
    width: 50%;

    display: none;
  }
  
  .overlay ul li a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    transform: translateX(-50%);
    height: 3px;
    background: #FFF;
    transition: 0.35s;
  }
  
  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
  
    100% {
      opacity: 1;
      left: 0;
    }
  }
  
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
  
    100% {
      opacity: 1;
      left: 0;
    }
  }
  
  
  
  .para01{
    color: #FFF;
    font-size: 14px;
    margin-right: 20px;
    font-family: Nexa-bold;
  }
  
  #clientulli{
    margin-left: 100px;
  }
  
  #clientulli1{
    margin-left: 60px;
  }

  html{
    overflow-x: hidden;
  }