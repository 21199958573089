@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700;800&display=swap');

.solfrist {
    margin-top: 2rem;
    padding: 0 160px;

   
  
}

.sol_header>span {
    font-family: NexaBold;
    font-size: 30.5998px;
    line-height: 55px;
    display: flex;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    margin-bottom: 20px;
    color: #232323;



}

.sol_fristheading>h2,
.sol_secheading>h2,
.sol_thiredheading>h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 220px;
    color: var(--Primary);
    line-height: 11.5rem;
 
}

@media (min-width:320px) and (max-width:480px) {

    .solfrist {
        margin-top: 5rem;
        padding: 0 0 200px 40px;
    }

    .sol_fristheading>h2,
    .sol_secheading>h2,
    .sol_thiredheading>h2 {
        font-size: 43px;
        line-height: 3rem;
    }

    .sol_header>span {
        font-size: 38px;
        line-height: 45px;
    }
}

@media (min-width:481px) and (max-width:641px) {
    .solfrist {
        padding: 0 0 200px 100px;
    }

    .sol_fristheading>h2,
    .sol_secheading>h2,
    .sol_thiredheading>h2 {
        font-size: 58px;
        line-height: 4rem;
    }
}

@media (min-width:641px) and (max-width:961px) {
    .solfrist {
        padding: 0 0 200px 100px;
    }

    .sol_fristheading>h2,
    .sol_secheading>h2,
    .sol_thiredheading>h2 {
        font-size: 80px;
        line-height: 4.5rem;
    }
}

@media (min-width:700px) and (max-width: 960px) {

    .sol_fristheading>h2,
    .sol_secheading>h2,
    .sol_thiredheading>h2 {
        font-size: 97px;
        line-height: 6rem;
    }
}

@media (min-width:962px) and (max-width:1280px) {

    .sol_fristheading>h2,
    .sol_secheading>h2,
    .sol_thiredheading>h2 {
        font-size: 125px;
        line-height: 7rem;
    }
}

@media(min-width:1281px) and (max-width:1480px){

    .sol_fristheading>h2,
    .sol_secheading>h2,
    .sol_thiredheading>h2 {
        font-size: 170px;
        line-height: 9rem;
    }
}